import React, { useEffect, useState } from 'react';
import { getUserHasInvitedUs, getWebAccessTokensInitialized, updateUserHasInvitedUs } from 'src/api';
import { CenteredModalHeader, StyledModal, StyledModalBody, StyledModalTitle } from './style';
import { Checkbox, Button } from '@doar/components';
import { useNavigate } from 'react-router-dom';

const WebAccessModal = ({ userId, pms, token }: {userId: string, pms: string, token: string}) => {
    const [show, setShow] = useState(false);
    const [hasAddedBestyAccount, setHasAddedBestyAccount] = useState(false);
    const [isGoingBack, setIsGoingBack] = useState(false);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const capitalPms = pms.charAt(0).toUpperCase() + pms.slice(1);

    const label1 = `I have invited Besty to my ${capitalPms} account (step 2)`;

    async function shouldShow() {
        getWebAccessTokensInitialized(userId).then((result) => {
            setShow(!(result?.initialized));
        });
    }

  const NavigateToSettingsButton = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      navigate('/settings');
    };

    return (
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Set up Besty
      </Button>
    );
  };

    useEffect(() => {
        shouldShow();
        getUserHasInvitedUs(userId).then((result) => {
            setHasAddedBestyAccount(result?.hasInvitedUs || false);
        });
    }, []);

    const handleContinue = async () => {
        await updateUserHasInvitedUs(userId, true);
        setIsGoingBack(false);
        setHasAddedBestyAccount(true);
        setCheckbox1(false);
        setCheckbox2(false);
    };

    return (
        <StyledModal show={show} size="lg" onClose={() => { setShow(false); }}>
            <StyledModalBody>
                {hasAddedBestyAccount && !isGoingBack ? (
                    <div>
                    <h1>Setup Hostfully for Besty</h1>
                    <p>Now that you've invited Besty to your {capitalPms} account, your account will be ready for use within 2 business days. In the meantime, click "Set up Besty" below to get started.</p>
                    <Button variant="outlined" color="secondary" onClick={() => setIsGoingBack(true)}>Back</Button>
                    <NavigateToSettingsButton />
                    </div>
                ) : (
                    <div>
                        {pms.toLowerCase() === 'hostfully' ? (
                            <div>
                            <h1>Setup Hostfully for Besty</h1>
                            <p>To start using Besty with {capitalPms}, follow these quick instructions:</p>
                            <p>
                                <u>Step 1:</u>
                                <br />
                                Go to your Hostfully <a href="https://platform.hostfully.com/app/#/agency-settings" target="_blank" rel="noopener noreferrer">agency settings page</a>. Scroll to the bottom of the page and make sure that under the "Activate Multi Factor Authentication" section, the Authentication Type is set to "Email". Click "Update".
                            </p>
                            <div style={{ textAlign: 'center' }}>
                                <img style={{ width: '400px', height: 'auto' }} src="/hostfullyEmailAuthentication.png" alt="Hostfully 2fa page" />
                            </div>
                            <p>
                                <u>Step 2:</u>
                                <br />
                                Go to your Hostfully <a href="https://platform.hostfully.com/app/#/l/agency_invite.jsp?from=agency" target="_blank" rel="noopener noreferrer">invite team members page</a>. Select "Manager" as the "role", and under "Enter your peers' email addresses", copy and paste this email: <i>{`accounts+${token}@getbesty.ai`}</i>
                                <br />
                                Then click "Send Invites".
                            </p>
                            <div style={{ textAlign: 'center' }}>
                                <img style={{ width: '400px', height: 'auto' }} src="/hostfullyInvite.png" alt="Hostfully invite page" />
                            </div>
                            <p>Once you have completed these steps, check the following boxes and click continue:</p>
                            <div>
                                <Checkbox id="2FA" name="2FA" label="I have enabled email Multi Factor Authentication (Step 1)" checked={checkbox2} onChange={() => setCheckbox2(!checkbox2)} />
                            </div>
                            <div>
                                <Checkbox id="invited" name="invited" label={label1} checked={checkbox1} onChange={() => setCheckbox1(!checkbox1)} />
                            </div>
                            <Button 
                                variant="contained"
                                color="primary"
                                onClick={handleContinue}
                                disabled={!checkbox1 || !checkbox2}
                            >
                                Continue
                            </Button>
                            </div>
                        ) : (
                            <div>
                            <h1>Use Besty with {capitalPms}</h1>
                            <p>To start using Besty with {capitalPms}, you'll need to add us as a trusted account.
                                To do so, go to... or watch the video below.
                            </p>
                            <p>Enter in this email: {`accounts+${userId}@getbesty.ai`}</p>
                            <p>Enter in this password: {"password"}</p>
                            <p>When you've added your account, click continue.</p>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                await updateUserHasInvitedUs(userId, true);
                                setIsGoingBack(false);
                                setHasAddedBestyAccount(true);
                                }}
                            >
                                Continue
                            </Button>
                            </div>
                        )}
                    </div>
                )}
            </StyledModalBody>
        </StyledModal>
    );
};

export default WebAccessModal;
