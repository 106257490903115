import {
    SendMessageRequest,
    GetThreadsRequest,
    GetThreadsResponse,
    RemoveThreadRequest,
    GetUserInfoRequest,
    GetUserInfoResponse,
    GetListingsRequest,
    GetListingsResponse,
    EnableListingsRequest,
    DisableListingsRequest,
    GetHostNotesRequest,
    GetHostNotesResponse,
    UpdateHostNotesRequest,
    UpdateHostNotesResponse,
    AskQuestionRequest,
    AskQuestionResponse,
    GetAnalyticsRequest,
    GetAnalyticsResponse,
    UpdateSettingsRequest,
    UpdateSettingsResponse,
    UpdateWidgetSettingsRequest,
    UpdateWidgetSettingsResponse,
    UpdateAutopilotSettingsRequest,
    UpdateAutopilotSettingsResponse,
    UpdateOrphanNightsSettingsRequest,
    GetOrphanNightsSettingsRequest,
    UpdateOrphanNightsSettingsResponse,
    GetSettingsRequest,
    GetSettingsResponse,
    GetWidgetSettingsRequest,
    GetWidgetSettingsResponse,
    GetAutopilotSettingsRequest,
    GetListingDataPointsRequest,
    GetListingDataPointsResponse,
    GetAutopilotSettingsResponse,
    GetOrphanNightsSettingsResponse,
    LoginInfo,
    LoginResponse,
    SignupInfo,
    GetQuoteRequest,
    GetUpgradeQuoteRequest,
    GetQuoteResponse,
    GetTasksRequest,
    GetTasksResponse,
    UpdateTasksRequest,
    UpdateTasksResponse,
    GetInquiryWinbackSettingsRequest,
    GetInquiryWinbackSettingsResponse,
    UpdateInquiryWinbackSettingsRequest,
    UpdateInquiryWinbackSettingsResponse,
    GetCopilotThreadsRequest,
    GetCopilotThreadsResponse,
    AskCopilotRequest,
    AskCopilotResponse,
    CreateCopilotThreadRequest,
    CopilotThread,
    DeleteCopilotThreadRequest,
    SettleConfirmationRequest,
    UpdateGeneralSettingsRequest,
    UpdateGeneralSettingsResponse,
    GetEarlyCheckinUpsellsRequest,
    UpdateEarlyCheckinUpsellsRequest,
    UpdateEarlyCheckinUpsellsResponse,
    GetEarlyCheckinUpsellsResponse,
    GetReviewsRequest,
    GetReviewResponderSettingsRequest,
    SendReviewReplyRequest,
    GetReviewResponderSettingsResponse,
    RetargetingSettings,
    GetAttributionsResponse,
    GetAttributionsRequest,
    GetBillingSettingsRequest,
    GetBillingSettingsResponse,
    UpdateBillingSettingsRequest,
    UpdateBillingSettingsResponse,
    GetSentimentsRequest,
    GetSentimentsResponse,
    GetAgentStatsRequest,
    GetAgentStatsResponse,
    RejectAttributionRequest,
    FetchUsersRequest,
    FetchUsersResponse
} from "./api-client-types";
import {
    mockGetThreadsResponse,
    mockGetListingsResponse,
    mockGetAnalyticsResponse,
    mockGetSettingsResponse,
} from "src/mock-data/";

import { v4 as uuidv4 } from "uuid";

import { client } from "./constants";

export const getDashboardState = () => {
    const resp = {
        isEnabled: true,
    };
    return resp;
};

export const setShownFirstMessagePopup = async(
    req: { userToken: string }
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.post(`/setShownFirstMessagePopup`, { userToken: req.userToken }).then(() => {
            resolve();
        });
    });
}

export const sendMessage = async(
    req: SendMessageRequest
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.post(`/sendMessage`, { conversationId: req.conversationId, text: req.text, userToken: req.userToken }).then(() => {
            resolve();
        });
    });
}

export const getThreads = async (
    req: GetThreadsRequest
): Promise<GetThreadsResponse> => {
    try {
        const response = await client.get(`/unansweredMessages`, { params: { 
            userToken: req.userToken, 
            listingId: req.listingId, 
            page: req.page,
            paginationLimit: req.paginationLimit,
            offset: req.offset,
            messagesToFetch: req.messagesToFetch,
            all: req.all,
        } });
        return { error: false, count: response.data.count, threads: response.data.threads };
    } catch (error) {
        return { error: true, count: 0, threads: null };
    }
};

export const archiveThread = async (
    req: RemoveThreadRequest
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.delete(`/unansweredMessages`, { params: { userToken: req.userToken, threadId: req.threadId } }).then((res) => {
            resolve();
        });
    });
};

export const archiveAllThreads = async(
    req: { userToken: string }
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.delete(`/unansweredMessages`, { params: { userToken: req.userToken, threadId: 'all' } }).then((res) => {
            resolve();
        });
    });
}

export const authUserToken = async (
    req: { userToken: string }
): Promise<{ loginStatus: number, userId: number} | null> => {
    return new Promise((resolve, _) => {
        client.get(`/authUserToken`, { params: { userToken: req.userToken } }).then((res) => {
            resolve({ loginStatus: res.data.loginStatus, userId: res.data.userId });
        }).catch(() => {
            resolve(null);
        });
    });
};

export const getWebAccessTokensInitialized = async (userId: string): Promise<{ initialized: boolean} | null> => {
    return new Promise((resolve, _) => {
        console.log(userId);
        client.get(`/getWebAccessTokensInitialized`, { params: { userToken: userId } }).then((res) => {
            resolve({ initialized: res.data.initialized });
        }).catch(() => {
            resolve(null);
        });
    });
}

export const getUserHasInvitedUs = async (
    userId: string
): Promise<{ hasInvitedUs: boolean} | null> => {
    return new Promise((resolve, _) => {
        client.get(`/getUserHasInvitedUs`, { params: { userToken: userId } }).then((res) => {
            resolve({ hasInvitedUs: res.data.hasInvitedUs });
        }).catch(() => {
            resolve(null);
        });
    });
};

export const updateUserHasInvitedUs = async (
    userId: string, hasInvitedUs: boolean
): Promise<{ status: number} | null> => {
    return new Promise((resolve, _) => {
        client.post(`/updateUserHasInvitedUs`, { userToken: userId, hasInvitedUs: hasInvitedUs }).then((res) => {
            resolve({ status: res.status });
        }).catch(() => {
            resolve(null);
        });
    });
};

// export const authUserId = async (
//     req: {userToken: string}
// ): Promise<object> => {
//     return new Promise((resolve, _) => {
//         client.post(`/authUserId`, {  userToken: req.userToken }).then((res) => {
//             resolve(res.data);
//         }).catch((err) => {
//             resolve({loginStatus: 3});
//         });
//     });
// } 

export const getUserInfo = async (
    req: GetUserInfoRequest
): Promise<GetUserInfoResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/userInfo`, { params: { userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const getListings = async (
    req: GetListingsRequest
): Promise<GetListingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/listings`, { params: { userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const enableListings = async (
    req: EnableListingsRequest
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.post(`/toggleListings`, { userToken: req.userToken, listingIds: req.listingIds, enabled: true }).then(() => {
            resolve();
        });
    });
};

export const disableListings = async (
    req: DisableListingsRequest
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.post(`/toggleListings`, { userToken: req.userToken, listingIds: req.listingIds, enabled: false }).then(() => {
            resolve();
        });
    });
};

export const getSentiments = async (
    req: GetSentimentsRequest
): Promise<GetSentimentsResponse> => {
    return client.get(`/sentiments`, { params: req }).then((res) => res.data);
}

export const askQuestion = async (
    req: AskQuestionRequest
): Promise<AskQuestionResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/sendSandboxMessage`, req).then((res) => {
            resolve(res.data);
        }).catch(() => {
            resolve({ message: { id: uuidv4(), text: "Sorry, we couldn't process your message. Please try again later.", sender: "besty", escalated: true } });
        });
    });
};

export const getAnalytics = async (
    req: GetAnalyticsRequest
): Promise<GetAnalyticsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/dashboard`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};
export const getWidgetSettings = async (
    req: GetWidgetSettingsRequest
): Promise<GetWidgetSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/widgetSettings`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
}
export const updateWidgetSettings = async (
    req: UpdateWidgetSettingsRequest
): Promise<UpdateWidgetSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/widgetSettings`, req);
        resolve({});
    });
};
export const getAutopilotSettings = async (
    req: GetAutopilotSettingsRequest
): Promise<GetAutopilotSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/autopilotSettings`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const getListingDataPoints = async (
    req: GetListingDataPointsRequest
): Promise<GetListingDataPointsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/getListingDataPoints`, { params: { listingId: req.listingId, userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const updateAutopilotSettings = async (
    req: UpdateAutopilotSettingsRequest
): Promise<UpdateAutopilotSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/autopilotSettings`, req);
        resolve({});
    });
};

export const getOrphanNightsSettings = async (
    req: GetOrphanNightsSettingsRequest
): Promise<GetOrphanNightsSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/orphanSettings`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const updateOrphanNightsSettings = async (
    req: UpdateOrphanNightsSettingsRequest
): Promise<UpdateOrphanNightsSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/orphanSettings`, req);
        resolve({});
    });
};

export const updateSettings = async (
    req: UpdateSettingsRequest
): Promise<UpdateSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/settings`, req);
        resolve({});
    });
};

export const updateGeneralSettings = async (
    req: UpdateGeneralSettingsRequest
): Promise<UpdateSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/updateGeneralSettings`, req);
        resolve({});
    });
};

export const getSettings = async (
    req: GetSettingsRequest
): Promise<GetSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/settings`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const getUpgradeQuote = async (
    req: GetUpgradeQuoteRequest
): Promise<GetQuoteResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/getUpgradeQuote`, { params: req, headers: {"Cache-Control": "no-cache"} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const getQuote = async (
    req: GetQuoteRequest
): Promise<GetQuoteResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/getQuote`, { params: { userToken: req.userToken, quantity: req.quantity, period: req.period } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const loginUser = async (
    req: LoginInfo
): Promise<LoginResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/loginUser`, { email: req.email, password: req.password} ).then((res) => {
            resolve(res.data);
        }).catch(() => {
            resolve({loginStatus: 3, userToken: ""});
        });
    });
};

export const sendResetEmail = async (
    req: {email: string}
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.post(`/sendResetEmail`, { email: req.email }).then((res) => {
            resolve();
        }).catch(() => {
            resolve();
        });
    });
};

export const resetPassword = async (
    req: {password: string, besty_token: string}
): Promise<object> => {
    return new Promise((resolve, _) => {
        client.post(`/resetPassword`, { password: req.password, besty_token: req.besty_token }).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            resolve(err.data);
        });
    });
};

export const signupUser = async (
    req: SignupInfo
): Promise<object> => {
    return new Promise((resolve, __) => {
        client.post('/signupUser', {...req}).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            resolve(err.response.data);
        })
    })
};

export const addPmsInfo = async (
    req: {userToken: string, pmsId: string, pmsKey: string, pms: string}
): Promise<void> => {
    return new Promise((resolve, __) => {
        client.post('/addPMS', {...req}).then((res) => {
            resolve(res.data);
        }).catch(() => {
            resolve();
        })
    })
};

export const getReferrals = async (
    req: {userToken: string}
): Promise<object> => {
    return new Promise((resolve, __) => {
        client.post("/getReferrals", {...req}).then((res) => {
            resolve(res.data);
        }).catch((err) =>{
            resolve(err.response);
        })
    })
};

export const savePaypal = async (
    req: {email: string, userToken: string}
): Promise<void> => {
    return new Promise((resolve, __) => {
        client.post("/savePaypal", {...req}).then((res)=> {
            resolve();
        }).catch((err) => {
            resolve();
        })
    })
};

export const getCheckoutSession = async (
    req: {userToken: string, referral: string}
): Promise<object> => {
    return new Promise((resolve, __) => {
        client.post("/checkout", { userToken: req.userToken, referral: req.referral }).then((res)=> {
            resolve(res.data);
        }).catch((err) => {
            resolve(err.response);
        })
    })
};

export const updatePlan = async (
    req: {listings: Array<object>, userToken: string, period: string}
): Promise<object> => {
    return new Promise((resolve, __) => {
        client.post("/updatePlan", { listings: req.listings, userToken: req.userToken, period: req.period }).then((res)=> {
            resolve(res.data);
        }).catch((err) => {
            resolve(err.response);
        })
    })
};

export const getHostNotes = async (
    req: GetHostNotesRequest
): Promise<GetHostNotesResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/hostnotes`, { params: { userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const updateHostNotes = async (
    req: UpdateHostNotesRequest
): Promise<UpdateHostNotesResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/hostnotes`, req);
        resolve({});
    });
};

export const getEarlyCheckinUpsells = async (
    req: GetEarlyCheckinUpsellsRequest
): Promise<GetEarlyCheckinUpsellsResponse> => {
    return new Promise((resolve, reject) => {
        client.get(`/earlyCheckinUpsells`, { params: { userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        }).catch(reject);
    });
}

export const updateEarlyCheckinUpsells = async (
    req: UpdateEarlyCheckinUpsellsRequest
): Promise<UpdateEarlyCheckinUpsellsResponse> => {
    return new Promise((resolve, reject) => {
        client.post(`/earlyCheckinUpsells`, req).then(() => {
            resolve({});
        }).catch(reject);
    });
}
    

export const getTasks = async (
    req: GetTasksRequest
): Promise<GetTasksResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/tasks`, { params: { userToken: req.userToken } }).then((res) => {
            resolve(res.data);
        });
    });
};

export const updateTasks = async (
    req: UpdateTasksRequest
): Promise<UpdateTasksResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/tasks`, req);
        resolve({});
    });
};

export const getInquiryWinbackSettings = async (
    req: GetInquiryWinbackSettingsRequest
): Promise<GetInquiryWinbackSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/inquiryWinbackSettings`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const updateInquiryWinbackSettings = async (
    req: UpdateInquiryWinbackSettingsRequest
): Promise<UpdateInquiryWinbackSettingsResponse> => {
    return new Promise((resolve, _) => {
        client.post(`/inquiryWinbackSettings`, req);
        resolve({});
    });
};

export const getCopilotThreads = async (
    req: GetCopilotThreadsRequest
): Promise<GetCopilotThreadsResponse> => {
    return new Promise((resolve, _) => {
        client.get(`/copilot`, { params: { userToken: req.userToken} }).then((res) => {
            resolve(res.data);
        });
    });
};

export const askCopilot = async (
    req: AskCopilotRequest
): Promise<AskCopilotResponse | null> => {
    return new Promise((resolve, _) => {
        client.post("/askCopilot", req).then((res)=> {
            resolve(res.data);
        }).catch((err) => {
            resolve(null);
        })
    });
};

export const createCopilotThread = async (
    req: CreateCopilotThreadRequest
): Promise<CopilotThread | null> => {
    return new Promise((resolve, _) => {
        client.post("/createCopilotThread", req).then((res)=> {
            resolve(res.data);
        }).catch((err) => {
            resolve(null);
        })
    });
};

export const deleteCopilotThread = async (
    req: DeleteCopilotThreadRequest
): Promise<null> => {
    return new Promise((resolve, _) => {
        client.post("/deleteCopilotThread", req).then((res)=> {
            resolve(null);
        }).catch((err) => {
            resolve(null);
        })
    });
};

export const settleConfirmation = async (
    req: SettleConfirmationRequest
): Promise<null> => {
    return new Promise((resolve, _) => {
        client.post("/settleConfirmation", req).then((res)=> {
            resolve(null);
        }).catch((err) => {
            resolve(null);
        })
    });
};

export const teamMemberEmails = async (req: {
    userToken: string;
}): Promise<{ email: string, extension_installed_at: string | null }[]> => {
  return new Promise((resolve, _) => {
    client
      .get("/teamMemberEmails", { params: { userToken: req.userToken } })
      .then((res) => {
        resolve(res.data);
      });
  });
};

export const setFirstTimeListingsReady = async (
    req: { userToken: string }
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.get(`/setFirstTimeListingsReady`, { params: { userToken: req.userToken } }).then(() => {
            resolve();
        });
    });
}

export const getReviewResponderSettings = async(
    req: { userToken: string }
): Promise<GetReviewResponderSettingsResponse> => {
    return client.get(`/reviewResponderSettings`, { params: { userToken: req.userToken } }).then((res) => res.data);
}

export const setReviewResponderSettings = async(
    req: GetReviewResponderSettingsRequest
): Promise<void> => {
    return client.post(`/reviewResponderSettings`, req)
}

export const getReviews = async(
    req: GetReviewsRequest
): Promise<object> => {
    return client.get(`/reviews`, { params: req }).then((res) => res.data);

}

export const sendReviewReply = async(
    req: SendReviewReplyRequest
): Promise<void> => {
    return client.post(`/sendReviewReply`, req)
}

export const getRetargetingSettings = async(
    req: { userToken: string }
): Promise<object> => {
    return client.get(`/retargetingSettings`, { params: req }).then((res) => res.data);
}

export const setRetargetingSettings = async(
    req: { userToken: string, settings: RetargetingSettings }
): Promise<void> => {
    return client.post(`/retargetingSettings`, req)
}
export const getAttributions = async(
    req: GetAttributionsRequest
): Promise<GetAttributionsResponse> => {
    return client.get(`/attributions`, { params: req }).then((res) => res.data);
}

export const rejectAttribution = async (
    req: RejectAttributionRequest
): Promise<void> => {
    return new Promise((resolve, _) => {
        client.delete(`/attributions`, { params: { userToken: req.userToken, attributionTableId: req.attributionTableId } }).then((res) => {
            resolve();
        });
    });
};

export const getBillingSettings = async(
    req: GetBillingSettingsRequest
): Promise<GetBillingSettingsResponse> => {
    return client.get(`/billingSettings`, { params: req }).then((res) => res.data);
}

export const updateBillingSettings = async(
    req: UpdateBillingSettingsRequest
): Promise<UpdateBillingSettingsResponse> => {
    return client.post(`/billingSettings`, req).then((res) => res.data);
}

export const getAgentStats = async (req: GetAgentStatsRequest): Promise<GetAgentStatsResponse> => {
    return client.get(`/agentStats`, { params: req }).then((res) => res.data);
}

export const syncListings = async (req: { userToken: string }): Promise<void> => {
    return await client.post(`/syncListings`, req).then(() => {});
}
export const fetchUserById = async (
    req: { userId: string }
): Promise<any> => {
    return new Promise((resolve, reject) => {
        client.get(`fetchUsers`, { params: { userId: req.userId } })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const fetchUsers = async (
    req: FetchUsersRequest
): Promise<FetchUsersResponse> => {
    return new Promise((resolve, reject) => {
        client.get(`/users`, { params: { userToken: req.userToken } })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getReviewTasks = async (
    req: { userToken: string }
): Promise<GetTasksResponse> => {
    return new Promise((resolve, reject) => {
        console.log('User token:', req.userToken); // Log user token
        client.get(`/reviewTasks`, { params: { userToken: req.userToken } })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export type SetReviewReadRequest = {
    userId: string;
    setRead: boolean;
    review_id: string;
};

export const setReviewRead = async (
    req: SetReviewReadRequest
): Promise<{ success: boolean; message: string } | null> => {
    return new Promise((resolve, reject) => {
        client.get(`setReviewRead`, { 
            params: { 
                userId: req.userId,
                setRead: req.setRead,
                review_id: req.review_id
            } 
        })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            console.error('Error in setReviewRead:', error);
            reject(error);
        });
    });
};

export const createHostawayTask = async (
    req: {
        userId: string,
        title: string,
        description?: string,
        assigneeId?: string
    }
): Promise<{ message: string, taskId: string } | null> => {
    return new Promise((resolve, _) => {
        client.post("/createHostawayTask", req).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            console.error("Error creating Hostaway task:", err);
            resolve(null);
        });
    });
};

export const createGuestyTask = async (
    req: {
        userId: string,
        title: string,
        description?: string,
        assigneeId?: string
    }
): Promise<{ message: string, taskId: string } | null> => {
    return new Promise((resolve, _) => {
        client.post("/createGuestyTask", req).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            console.error("Error creating Guesty task:", err);
            resolve(null);
        });
    });
};

export const setResolved = async (
    req: {
        userToken: string,
        reservationId: string,
        resolved: boolean
    }
): Promise<{ success: boolean, message: string } | null> => {
    return new Promise((resolve, _) => {
        client.post("/setResolved", req).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            console.error("Error setting resolved:", err);
            resolve(null);
        });
    });
}