import { createSlice } from "@reduxjs/toolkit";
import { Thread } from "src/api/";
import { RootState } from "src/redux/store";

export interface MessagesState {
    threads: Thread[];
    selectedThreadId: string;
    unansweredMessages: number; // managed globally and locally
    messageStatusFetching: "Unanswered" | "All" | "Archived";
}

const initialState: MessagesState = {
    threads: [],
    selectedThreadId: "",
    unansweredMessages: 0,
    messageStatusFetching: "Unanswered"
};

const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setThreads: (state, action) => {
            state.threads = action.payload;
            if (state.selectedThreadId === "" && state.threads.length > 0) {
                state.selectedThreadId = action.payload[0].threadId;
            }
        },
        setSelectedThreadId: (state, action) => {
            state.selectedThreadId = action.payload;
        },
        removeThread: (state, action) => {
            const otherThreads = state.threads.filter((thread) => {
                return thread.threadId !== action.payload;
            });
            state.threads = otherThreads;
            state.selectedThreadId = otherThreads[0]?.threadId || "";
            state.unansweredMessages -= 1;
        },
        removeAllThreads: (state) => {
            state.threads = [];
            state.selectedThreadId = "";
            state.unansweredMessages = 0;
        },
        addMessage: (state, action) => {
            const selectedThread = state.threads.find(
                (thread) => thread.threadId === state.selectedThreadId
            );
            if (selectedThread) {
                selectedThread.thread.push(action.payload);
            }
        },
        setUnansweredMessages: (state, action) => {
            state.unansweredMessages = action.payload;
        },
        setMessageStatusFetching: (state, action) => {
            if(["Unanswered", "All", "Archived"].includes(action.payload))
                state.messageStatusFetching = action.payload;
        },
    },
});

export const {
    setThreads,
    setSelectedThreadId,
    removeThread,
    removeAllThreads,
    addMessage,
    setUnansweredMessages,
    setMessageStatusFetching
} = messagesSlice.actions;

export const getMessageStatusFetching = (state: RootState) => {
    return state.messages.messageStatusFetching;
};

export const getUnansweredCount = (state: RootState) => {
    return state.messages.unansweredMessages;
};

export const getSelectedThreadId = (state: RootState) => {
    return state.messages.selectedThreadId;
};

export const getThreadIds = (state: RootState) => {
    return state.messages.threads.map((t) => t.threadId);
};

export const selectThreads = (state: RootState) => {
    return state.messages.threads;
};

export const getSelectedThread = (state: RootState) => {
    return (
        state.messages.threads.find((t) => {
            return t.threadId === state.messages.selectedThreadId;
        }) || { threadId: "", guestName: "", guestAvatar: "", thread: [] }
    );
};

export default messagesSlice.reducer;
