import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { OrphanNightsSettings } from "src/api";

const DEFAULT_NIGHTLY_DISCOUNT = 30;
const DEFAULT_TEMPLATE_TEXT_ORPHAN = `Hi {guest_first_name}, 

Just wanted to let you know we have a gap between bookings in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:

{night}: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

No worries if your plans are already set, but figured we’d reach out and extend the offer!`;

/*
const DEFAULT_TEMPLATE_TEXT_BOOKEND = `Hi {guest_first_name}, 

Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:

If you want to come earlier, we have an availability the night of Tuesday, October 10th. Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.
If you want to come earlier, we have availabilities on the following nights leading up to your stay: 
Tuesday, October 10th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.
Wednesday, October 11th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

If you want to stay later, we have an availability the night of Wednesday, October 18th. Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

Let me know if you’re interested and look forward to hosting you!`;
*/

const DEFAULT_TEMPLATE_TEXT_BOOKEND = `Hi {guest_first_name}, 

Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:

{offer_details}

Let me know if you’re interested and look forward to hosting you!`;

/*
offer_details is one of:
1.
If you want to come earlier, we have an availability the night of Tuesday, October 10th. Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

2.
If you want to come earlier, we have availabilities on the following nights leading up to your stay: 
Tuesday, October 10th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.
Wednesday, October 11th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

3.
If you want to stay later, we have an availability the night of Wednesday, October 18th. Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.

4.
If you want to stay later, we have availabilities on the following nights after your stay:
Tuesday, October 10th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.
Wednesday, October 11th: Standard rate {standard_rate}/night. We’d offer it to you for {discounted_rate}/night.
*/



const DEFAULT_MESSAGE_DELAY_TYPE = "hours";
const DEFAULT_MESSAGE_DELAY_PREV_TYPE = "days";
const DEFAULT_MESSAGE_DELAY_POST_TYPE = "days";
const DEFAULT_ORPHAN_MODE = "bookend";
const DEFAULT_SUBJECT_TEXT = "Booking extension offer";

export interface GlobalState {
  orphanNightsSettings: OrphanNightsSettings;
}

const initialState: GlobalState = {
  orphanNightsSettings: {
    enabled: false,
    gapLength: 2,
    weeknightsOnly: false,
    nightlyDiscount: DEFAULT_NIGHTLY_DISCOUNT,
    templateText: DEFAULT_TEMPLATE_TEXT_ORPHAN,
    templateTextBookend: DEFAULT_TEMPLATE_TEXT_BOOKEND,
    messageDelay: 0,
    messageDelayType: DEFAULT_MESSAGE_DELAY_TYPE,
    channelRates: {},
    channelRatesEnabled: false,
    messageDelayPrev: 0,
    messageDelayPrevType: DEFAULT_MESSAGE_DELAY_PREV_TYPE,
    messageDelayPost: 0,
    messageDelayPostType: DEFAULT_MESSAGE_DELAY_POST_TYPE,
    orphanMode: DEFAULT_ORPHAN_MODE,
    isAllListings: true,
    listings: [],
    templateSubject: DEFAULT_SUBJECT_TEXT,
    fetched: false,
  },
};

const orphanNightsSettingsSlice = createSlice({
  name: "orphanNightsSettings",
  initialState,
  reducers: {
    setOrphanNightsSettings: (state, action) => {
      state.orphanNightsSettings = {
        ...state.orphanNightsSettings,
        ...action.payload,
        fetched: true,
      };
    },
  },
});

export const { setOrphanNightsSettings } = orphanNightsSettingsSlice.actions;

export const getOrphanNightsSettings = (state: RootState) => state.orphanNightsSettings.orphanNightsSettings;
export const DEFAULT_SETTINGS: Partial<OrphanNightsSettings> = { templateText: DEFAULT_TEMPLATE_TEXT_ORPHAN, templateTextBookend: DEFAULT_TEMPLATE_TEXT_BOOKEND, nightlyDiscount: DEFAULT_NIGHTLY_DISCOUNT, messageDelayType: DEFAULT_MESSAGE_DELAY_TYPE, messageDelayPrevType: DEFAULT_MESSAGE_DELAY_PREV_TYPE, messageDelayPostType: DEFAULT_MESSAGE_DELAY_POST_TYPE, orphanMode: DEFAULT_ORPHAN_MODE, isAllListings: true, listings: []};

export default orphanNightsSettingsSlice.reducer;
